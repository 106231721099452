import React from 'react';
import LogoFull from '../Root/LogoSide'; // Import your custom logo
import { ToolboxHomepageCard } from '@drodil/backstage-plugin-toolbox';
import { Page, Content } from '@backstage/core-components';
import {
  HomePageCompanyLogo,
  HomePageStarredEntities,
  CustomHomepageGrid,
  HomePageRandomJoke,
  HomePageTopVisited,
  HomePageRecentlyVisited,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useLogoStyles } from './shared';
import { JiraUserIssuesViewCard } from '@axis-backstage/plugin-jira-dashboard';
import { CostOverview } from './CostOverview';
import { ServiceOverview } from './ServiceOverview';
import { Alerts } from './Alerts';
import { OperationalTasks } from './OperationalTasks';
import { Ecswidget, Lambdawidget, Efswidget, Rdswidget } from '@internal/backstage-plugin-monitoring'


const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  descriptionText: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export const HomePage = () => {
  const { container } = useLogoStyles();
  const classes = useStyles();
  return (
    <Page themeId="home">
      <Content>
        <Grid container justifyContent="center">
          <HomePageCompanyLogo className={container} logo={<LogoFull />} />
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <HomePageSearchBar
            InputProps={{
              classes: {
                root: classes.searchBarInput,
                notchedOutline: classes.searchBarOutline,
              },
            }}
            placeholder="Search"
          />
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Typography variant="body1" className={classes.descriptionText}>
            Welcome to the ITSyndicate internal platform.<br />
            This page is editable to suit your needs, just click the edit button and shape the view below as you wish.
          </Typography>
        </Grid>
        <CustomHomepageGrid>
          <HomePageRecentlyVisited />
          <ToolboxHomepageCard />
          <HomePageStarredEntities />
          <HomePageRandomJoke />
          <Ecswidget />
          <Efswidget />
          <Rdswidget />
          <Lambdawidget />
        </CustomHomepageGrid>
      </Content>
    </Page>
  );
};
