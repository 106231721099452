import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const monitoringPlugin = createPlugin({
  id: 'monitoring',
  routes: {
    root: rootRouteRef,
  },
});

export const MonitoringPage = monitoringPlugin.provide(
  createRoutableExtension({
    name: 'MonitoringPage',
    component: () =>
      import('./components/MonitoringPage').then(m => m.MonitoringPage),
    mountPoint: rootRouteRef,
  }),
);
export const Ecswidget = monitoringPlugin.provide(
  createComponentExtension({
    name: 'Ecs Services',
    component: {
      lazy: () =>
        import('./components/HomePage').then(
          m => m.EcsServiceCount,
        ),
    },
  }),
);
export const Lambdawidget = monitoringPlugin.provide(
  createComponentExtension({
    name: 'Lambda Functions',
    component: {
      lazy: () =>
        import('./components/HomePage').then(
          m => m.LambdaFunctions,
        ),
    },
  }),
);
export const Efswidget = monitoringPlugin.provide(
  createComponentExtension({
    name: 'EFS',
    component: {
      lazy: () =>
        import('./components/HomePage').then(
          m => m.EfsDetails,
        ),
    },
  }),
);
export const Rdswidget = monitoringPlugin.provide(
  createComponentExtension({
    name: 'RDS Instnaces',
    component: {
      lazy: () =>
        import('./components/HomePage').then(
          m => m.RdsInstances,
        ),
    },
  }),
);