// plugins/aws-cloudwatch/src/api/AwsCloudWatchApi.ts

import { createApiRef, DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

/**
 * Represents a single CloudWatch Alarm.
 */
export enum ComparisonOperator {
  GreaterThanOrEqualToThreshold = 'GreaterThanOrEqualToThreshold',
  GreaterThanThreshold = 'GreaterThanThreshold',
  LessThanThreshold = 'LessThanThreshold',
  LessThanOrEqualToThreshold = 'LessThanOrEqualToThreshold',
  LessThanLowerOrGreaterThanUpperThreshold = 'LessThanLowerOrGreaterThanUpperThreshold',
  LessThanLowerThreshold = 'LessThanLowerThreshold',
  GreaterThanUpperThreshold = 'GreaterThanUpperThreshold',
}

/**
 * Represents a single CloudWatch Alarm.
 */
export interface Alarm {
  AlarmName: string;
  StateValue: 'OK' | 'ALARM' | 'INSUFFICIENT_DATA';
  MetricName: string;
  Namespace: string;
  Threshold: number;
  ComparisonOperator: ComparisonOperator;
  EvaluationPeriods: number;
  Statistic: string;
  Period: number;
  Dimensions: { Name: string; Value: string }[];
  AlarmDescription?: string;
  ActionsEnabled?: boolean;
  url: string; 
  conditions: string;
}

/**
 * Represents alarms data grouped by region.
 */
export interface RegionAlarms {
  region: string;
  alarms: Alarm[];
}

/**
 * Represents a CloudWatch Dashboard.
 */
export interface Dashboard {
  region: string;
  dashboardName: string;
  dashboardBody: string; // JSON string representing the dashboard configuration
}

/**
 * Represents the properties of a Metric Widget.
 */
export interface MetricWidgetProperties {
  title?: string;
  metrics: string[][]; // Example: [['AWS/ECS', 'CPUUtilization', 'ServiceName', 'my-service']]
  // Add other properties based on your widget configurations
}

/**
 * Represents a Metric Widget.
 */
export interface MetricWidget {
  type: 'metric';
  properties: MetricWidgetProperties;
}

/**
 * Represents the properties of a Text Widget.
 */
export interface TextWidgetProperties {
  markdown: string;
}

/**
 * Represents a Text Widget.
 */
export interface TextWidget {
  type: 'text';
  properties: TextWidgetProperties;
}

/**
 * Union type for all supported widgets.
 */
export type Widget = MetricWidget | TextWidget;

/**
 * Represents the body of a Dashboard containing widgets.
 */
export interface DashboardBody {
  widgets: Widget[];
}

/**
 * Represents the response for Widget Metrics.
 */
export interface WidgetMetricsResponse {
  [metricId: string]: {
    Id: string;
    Label: string;
    Timestamps: string[]; // ISO 8601 strings
    Values: number[];
    StatusCode: string;
    StorageClass?: string; // Optional, depending on metric
    // Add other relevant fields as needed
  }[];
}

/**
 * Represents the Metrics Response.
 * Adjust the structure based on your actual API response.
 */
export interface MetricsResponse {
  [key: string]: any[]; // Define more precise types if possible
}

/**
 * Represents EFS Metrics Response.
 */
export interface EfsMetricDataResult {
  Id: string;
  Label: string;
  Timestamps: string[];
  Values: number[];
  StatusCode: string;
  StorageClass?: string;
}

/**
 * Represents specific EFS Metrics.
 */
export interface StorageBytesMetric extends EfsMetricDataResult {
  StorageClass: string;
}

export interface AccessPointCountMetric extends EfsMetricDataResult {
  // If there are specific fields for AccessPointCountMetric, add them here
  // For example:
  // SomeField: string;
}

/**
 * Represents EFS Metrics.
 */
export interface EfsMetricsResponse {
  [fileSystemId: string]: EfsMetricDataResult[];
}

/**
 * Represents ECS Service Count Response.
 */
export interface EcsServiceCountResponse {
  count: number;
}

/**
 * Represents Log Groups Response.
 */
export interface LogsResponse {
  logGroupName: string;
  region: string;
  consoleLink: string;
  // Add other fields as needed
}

/**
 * Represents Alarms Response.
 */
export interface AlarmsApiResponse {
  [region: string]: Alarm[];
}

/**
 * Represents Category Logs.
 */
export interface CategoryLogs {
  category: string;
  logGroups: LogsResponse[];
}

/**
 * Represents Website Health Check Status.
 */
export interface WebsiteHealthStatus {
  url: string;
  status: number | string | undefined;
  timestamps?: string[];
  values?: number[];
}

/**
 * Represents SSL Check Status.
 */
export interface SSLCheckStatus {
  url: string;
  validTo?: Date;
  daysRemaining?: number;
  error?: string;
}

/**
 * Represents Lambda Error Counts.
 */
export interface LambdaErrorCount {
  functionName: string;
  errorCount: number;
}

/**
 * Represents Latest Backup Data.
 */
export interface LatestBackupData {
  bucketName: string;
  latestBackup?: string;
  lastModified?: Date;
}

/**
 * Represents Database Statuses.
 */
export interface DatabaseStatuses {
  dynamoDB: DynamoDBStatus[];
  rds: RDSStatus[];
}

/**
 * Represents DynamoDB Table Status.
 */
export interface DynamoDBStatus {
  tableName: string;
  status?: string;
  itemCount?: number;
  // Add more fields as needed
}

/**
 * Represents RDS Instance Status.
 */
export interface RDSStatus {
  dbInstanceIdentifier?: string;
  status?: string;
  engine?: string;
  // Add more fields as needed
}

export interface MetricDataResult {
  Id: string;
  Label: string;
  Timestamps: string[];
  Values: number[];
  StatusCode: string;
  Messages?: string[];
}
export interface Resource {
  // Optional identifiers for different resource types
  instanceId?: string;
  volumeId?: string;
  dbInstanceId?: string;
  dbClusterId?: string;
  tableName?: string;
  apiId?: string;
  queueUrl?: string;
  name: string;
  url: string;
  metrics: MetricDataResult[];
}
/**
 * Create an API reference for AwsCloudWatchApi.
 */
export const awsCloudWatchApiRef = createApiRef<AwsCloudWatchApi>({
  id: 'plugin.aws-cloudwatch.service',
});
export interface AutomaticDashboard {
  resourceType: string;
  resources: Resource[];
}
/**
 * Define the AwsCloudWatchApi interface.
 */
export interface AwsCloudWatchApi {
  getWidgetMetrics(widget: Widget, region: string): Promise<WidgetMetricsResponse>;
  getMetrics(): Promise<MetricsResponse>;
  getDashboards(): Promise<Dashboard[]>;
  getEfsMetrics(): Promise<EfsMetricsResponse>;
  getEcsServiceCount(): Promise<number>;
  getLogs(): Promise<LogsResponse[]>;
  getAlarms: () => Promise<Alarm[]>;

  // New methods
  getWebsiteHealthStatuses(): Promise<WebsiteHealthStatus[]>;
  getSSLCheckStatuses(): Promise<SSLCheckStatus[]>;
  getLambdaErrorCounts(): Promise<LambdaErrorCount[]>;
  getLatestBackup(): Promise<LatestBackupData[]>;
  getDatabaseStatuses(): Promise<DatabaseStatuses>;
  getAutomaticDashboards(): Promise<AutomaticDashboard[]>;
}

/**
 * Implement the AwsCloudWatchApi interface.
 */
export class AwsCloudWatchClient implements AwsCloudWatchApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly identityApi: IdentityApi,
  ) {}

  /**
   * Constructs the authorization headers required for API requests.
   */
  private async getAuthorizationHeader(): Promise<HeadersInit> {
    const { token } = await this.identityApi.getCredentials();
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }

  /**
   * Retrieves the base URL for the AWS CloudWatch backend service.
   */
  private async getBaseUrl(): Promise<string> {
    return await this.discoveryApi.getBaseUrl('aws-cloudwatch');
  }

  /**
   * Fetches widget metrics based on the widget configuration and region.
   */
  async getWidgetMetrics(widget: Widget, region: string): Promise<WidgetMetricsResponse> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/widget-metrics`;

    const response = await fetch(url, {
      method: 'POST',
      headers: await this.getAuthorizationHeader(),
      body: JSON.stringify({ widget, region }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch widget metrics, status ${response.status}`);
    }

    const data: WidgetMetricsResponse = await response.json();
    return data;
  }

  /**
   * Fetches general metrics.
   */
  async getMetrics(): Promise<MetricsResponse> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/metrics`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch metrics, status ${response.status}`);
    }

    const data: MetricsResponse = await response.json();
    return data;
  }

  /**
   * Fetches all CloudWatch dashboards.
   */
  async getDashboards(): Promise<Dashboard[]> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/dashboards`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch dashboards, status ${response.status}`);
    }

    const data: Dashboard[] = await response.json();
    return data;
  }

  /**
   * Fetches EFS metrics.
   */
  async getEfsMetrics(): Promise<EfsMetricsResponse> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/efs-metrics`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch EFS metrics, status ${response.status}`);
    }

    const data: EfsMetricsResponse = await response.json();
    return data;
  }

  /**
   * Fetches ECS service count.
   */
  async getEcsServiceCount(): Promise<number> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/ecs-service-count`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch ECS service count, status ${response.status}`);
    }

    const data: EcsServiceCountResponse = await response.json();
    return data.count;
  }

  /**
   * Fetches logs.
   */
  async getLogs(): Promise<LogsResponse[]> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/logs`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch logs, status ${response.status}`);
    }

    const data: LogsResponse[] = await response.json();
    return data;
  }

  /**
   * Fetches all CloudWatch Alarms.
   */
  async getAlarms(): Promise<Alarm[]> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/alarms`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch alarms, status ${response.status}`);
    }

    const data: Alarm[] = await response.json();

    // Return the array of alarms directly
    return data;
  }


  /**
   * Fetches website health check statuses.
   */
  async getWebsiteHealthStatuses(): Promise<WebsiteHealthStatus[]> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/website-health`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch website health statuses, status ${response.status}`);
    }

    const data: WebsiteHealthStatus[] = await response.json();
    return data;
  }

  /**
   * Fetches SSL check statuses.
   */
  async getSSLCheckStatuses(): Promise<SSLCheckStatus[]> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/ssl-check`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch SSL check statuses, status ${response.status}`);
    }

    const data: SSLCheckStatus[] = await response.json();
    return data;
  }

  /**
   * Fetches Lambda error counts.
   */
  async getLambdaErrorCounts(): Promise<LambdaErrorCount[]> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/lambda-errors`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch Lambda error counts, status ${response.status}`);
    }

    const data: LambdaErrorCount[] = await response.json();
    return data;
  }

  /**
   * Fetches the latest backup information.
   */
  async getLatestBackup(): Promise<LatestBackupData[]> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/latest-backup`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch latest backup data, status ${response.status}`);
    }

    const data: LatestBackupData[] = await response.json();
    return data;
  }

  /**
   * Fetches database statuses (DynamoDB and RDS).
   */
  async getDatabaseStatuses(): Promise<DatabaseStatuses> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/database-statuses`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch database statuses, status ${response.status}`);
    }

    const data: DatabaseStatuses = await response.json();
    return data;
  }
  async getAutomaticDashboards(): Promise<AutomaticDashboard[]> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/automatic-dashboards`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch automatic dashboards, status ${response.status}`);
    }

    const data: AutomaticDashboard[] = await response.json();
    return data;
  }
}
