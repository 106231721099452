import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { awsHealthApiRef, AwsHealthClient } from './components/api/AwsHealthApi';;
import { awsSecurityGroupsApiRef, AwsSecurityGroupsClient } from './components/api/AwsSecurityGroupsApi';
import { awsIamApiRef, AwsIamClient } from './components/api/AwsIamApi';
import { rootRouteRef } from './routes';

export const securityPlugin = createPlugin({
  id: 'security',
  apis: [
    createApiFactory({
      api: awsHealthApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new AwsHealthClient({ discoveryApi, identityApi }),
    }),
    createApiFactory({
      api: awsSecurityGroupsApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new AwsSecurityGroupsClient(discoveryApi, identityApi),
    }),
    createApiFactory({
      api: awsIamApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new AwsIamClient(discoveryApi, identityApi),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const SecurityPage = securityPlugin.provide(
  createRoutableExtension({
    name: 'SecurityPage',
    component: () =>
      import('./components/SecurityPage').then(m => m.SecurityPage),
    mountPoint: rootRouteRef,
  }),
);
