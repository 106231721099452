import React, { useEffect, useState } from 'react';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { gitLabApiRef } from './GitlabIssuesApi';
import { CircularProgress, Box, Typography, Button, Card, CardContent } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

interface BuildDetails {
  project_name: string;
  id: number;
  project_id: number;
  name: string;
  status: string;
  web_url: string;
  ref: string;
  sha: string;
}

interface LatestBuildWidgetProps {
  groupName: string;
  projectName: string;
}

export const LatestBuildWidget = ({ groupName, projectName }: LatestBuildWidgetProps) => {
  const gitLabApi = useApi(gitLabApiRef);
  const errorApi = useApi(errorApiRef);
  const [build, setBuild] = useState<BuildDetails | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLatestBuild = async () => {
      setLoading(true);
      try {
        const fetchedBuilds = await gitLabApi.getLatestBuildByGroup(groupName);
        const filteredBuild = fetchedBuilds.filter((build: BuildDetails) => build.project_name === projectName);

        if (filteredBuild.length > 0) {
          setBuild(filteredBuild[0]);
        } else {
          setBuild(null);
        }
      } catch (error) {
        errorApi.post(new Error(`Failed to fetch the latest build for group: ${groupName}, project: ${projectName}`));
      } finally {
        setLoading(false);
      }
    };

    fetchLatestBuild();
  }, [gitLabApi, errorApi, groupName, projectName]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!build) {
    return (
      <Alert severity="error">
        No build information available for project: {projectName}.
      </Alert>
    );
  }

  const statusColor = build.status === 'success' ? '#4caf50' : build.status === 'failed' ? '#f44336' : '#9e9e9e';
  const statusText = build.status === 'success' ? 'Passed' : 'Failed';
  const commitHash = build.sha.slice(0, 8);

  return (
    <Card style={{ width: '100%' }}>
      <CardContent style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
            Last Master Build
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{
              width: '100%',
              padding: '10px',
              margin: '12px 0',
              backgroundColor: statusColor,
              borderRadius: '4px',
              color: '#fff',
            }}
          >
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Status: {statusText}
            </Typography>
          </Box>

          <Box textAlign="center">
            <Typography variant="body2" style={{ color: '#616161' }}>
              <strong>Branch:</strong> {build.ref}
            </Typography>
            <Typography variant="body2" style={{ color: '#616161' }}>
              <strong>Commit:</strong> {commitHash}
            </Typography>
          </Box>
        </Box>

        <Box mt="auto">
          <Button
            variant="contained"
            color="primary"
            size="medium"
            href={build.web_url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textTransform: 'none',
              fontWeight: 'bold',
              boxShadow: 'none',
            }}
          >
            See More on GitLab
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};