// plugins/taskpage/src/plugin.ts

import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  identityApiRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { gitLabApiRef, GitLabApiClient } from './components/GitlabIssuesApi';
import { rootRouteRef } from './routes';

export const taskpagePlugin = createPlugin({
  id: 'taskpage',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: gitLabApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new GitLabApiClient(discoveryApi, identityApi), // Pass the arguments directly
    }),
  ],
});

export const TaskpagePage = taskpagePlugin.provide(
  createRoutableExtension({
    name: 'TaskpagePage',
    component: () =>
      import('./components/TaskPage').then(m => m.TaskPage),
    mountPoint: rootRouteRef,
  }),
);
